import React from 'react';
import Icon from 'components/Icon';
import classNames from 'classnames';
import Link from 'components/Link';

const Linker = ({
    id,
    codename,
    children,
    className,
    btn_size,
    cta_link,
    trigger,
    target,
    disable_icon = false,
}) => {
    return (
        <div
            className={classNames('link', {
                [`${className}`]: className,
                [`link--${btn_size}`]: btn_size && btn_size !== 'md',
            })}
            data-kontent-item-id={id}
            data-kontent-element-codename={codename}
        >
            {!cta_link && (
                <>
                    <button className="link__button" onClick={trigger}>
                        {children}{' '}
                        {!disable_icon && (
                            <Icon
                                icon={'fa-light fa-arrow-right'}
                                className="link__icon"
                            />
                        )}
                    </button>
                </>
            )}
            {cta_link && (
                <Link onClick={trigger} target={target} href={cta_link}>
                    {children}{' '}
                    {!disable_icon && (
                        <Icon
                            icon={'fa-light fa-arrow-right'}
                            className="link__icon"
                        />
                    )}
                </Link>
            )}
        </div>
    );
};

export default Linker;
