import classNames from 'classnames';
import Caption from 'components/Caption';
import { getImagelayout } from 'lib/utils/getMedia';
import ResponsiveImage from './ResponsiveImage';

const Image = ({ ...props }) => {
    const {
        image,
        wrapperClassName,
        layout,
        disableElevation,
        disableCaption,
    } = props;
    if (!image) {
        return null;
    }
    return (
        <>
            <div
                className={classNames('image', {
                    [`${wrapperClassName}`]: wrapperClassName,
                    [`image--${getImagelayout(layout, image)}`]: image,
                    [`image--${image.elevation}`]:
                        image.elevation && !disableElevation,
                    ['image--vertical']:
                        image?.center_alignment?.includes('vertical'),
                    ['image--horizontal']:
                        image?.center_alignment?.includes('horizontal'),
                })}
            >
                <ResponsiveImage {...props} breakpoint="mobile" />
                <ResponsiveImage {...props} breakpoint="tablet" />
                <ResponsiveImage {...props} breakpoint="desktop" />
            </div>
            {image?.caption && !disableCaption && (
                <Caption
                    caption={image.caption}
                    alignment={image.caption_alignment}
                    size={image.caption_size}
                />
            )}
        </>
    );
};

export default Image;
