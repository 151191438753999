import Navbar from 'components/Navbar';
import Head from 'components/Head';
import Footer from 'blocks/Footer';
import { useEffect } from 'react';
import Popups from 'components/Popups';
import StandaloneNavbar from 'components/StandaloneNavbar';
import StandaloneFooter from 'components/StandaloneFooter';
import UtilityNavigation from 'components/UtilityNavigation';

const Layout = ({
    children,
    head,
    navbar,
    footer,
    popups,
    standalone_page_settings,
    utility_navigation,
}) => {
    useEffect(() => {
        document.documentElement.setAttribute('lang', head?.language);
    }, []);
    return (
        <div
            className="wrapper"
            data-kontent-project-id={process.env.KONTENT_PROJECT_ID}
            data-kontent-language-codename={head?.language}
        >
            {head && <Head {...head} />}
            {popups && <Popups popups={popups} />}
            {!standalone_page_settings && (
                <>
                    {utility_navigation && (
                        <UtilityNavigation {...utility_navigation} />
                    )}
                    {navbar && <Navbar {...navbar} />}

                    {children}
                    {footer && <Footer {...footer} />}
                </>
            )}
            {standalone_page_settings && (
                <>
                    {navbar && (
                        <StandaloneNavbar {...standalone_page_settings} />
                    )}
                    {children}
                    {footer && (
                        <StandaloneFooter {...standalone_page_settings} />
                    )}
                </>
            )}
        </div>
    );
};

export default Layout;
